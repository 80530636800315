html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #222;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

#root {
  height: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
